import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import usePageBodyComponents from './usePageBodyComponents';

const StoryblokStory = ({ story }) => {
  const component = usePageBodyComponents({ content: story });

  return <Box>{component}</Box>;
};

StoryblokStory.propTypes = {
  story: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default StoryblokStory;
