import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box } from 'grommet';
import { get, head } from '../../lib/nodash';
import { useLocation } from '@reach/router';

import Loading from '../Loading';
import { storyblokClient } from '../../gatsby-theme-apollo/client';
import { CONTENT_NODES_BY_UID } from '../../queries/storyblokQueries';
import StoryblokStory from './StoryblokStory';

const DataStoryblokStory = ({ id }) => {
  const location = useLocation();
  const { data, loading } = useQuery(CONTENT_NODES_BY_UID, {
    variables: {
      id,
    },
    client: storyblokClient,
  });

  return loading ? (
    <Box pad="medium" align="center" justify="center">
      <Loading size="large" />
    </Box>
  ) : (
    <StoryblokStory
      story={get('content', head(get('ContentNodes.items', data)))}
      location={location}
    />
  );
};

DataStoryblokStory.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DataStoryblokStory;
